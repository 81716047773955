<template>
  <div class="">
    Sucursales
  </div>
</template>
<script>
export default {
  name: 'BranchOffices',
}
</script>
